import React, { useEffect } from 'react'
import ImageBanner from "../../img/services/asesoriatecnica.jpg";
import TechComputer from "../../img/services/details/technical-computer.png";
import Header from '../Header';
import { motion, useScroll } from "framer-motion";
import { fadeIn } from "../../variants";
import { Link } from 'react-router-dom';

import Seo from '../seo/Seo';

const AsesoriaTecnica = () => {
  const { scrollY } = useScroll();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-w-[1920px] mx-auto overflow-hidden bg-white">
      <Seo title={'Inversiones DS Solutions - Asesoria'} description={'Esta es la pagina de asesoria'} image={ImageBanner}/>
        {/* <Header />
        <div className=" relative w-full h-64 md:h-96 mt-20"> */}
        <div className=" relative w-full h-64 md:h-96">
            <img 
                src={ImageBanner} 
                alt="Asesoria Tecnica" 
                className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <h1 className="text-white text-4xl md:text-6xl font-bold text-center">
                    Asesoria técnica
                </h1>
            </div>
        </div>
        <Link to="/" className="inline-block px-4 py-2 mt-2 mb-2 ml-4 text-white bg-blue-500 rounded hover:bg-blue-600 transition duration-300 ease-in-out">
          Volver al inicio
        </Link>

        <section className="lg:py-16 xl:pb-[160px] bg-gray-100" id="about">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-x-[70px] items-center">
          {/* numbers */}
          <motion.div
            variants={fadeIn("right")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.1 }}

            className="flex flex-col lg:flex-row flex-1"
          >
            {/* <div className="text-[260px] xl:text-[520px] leading-none font-tertiary lg:-tracking-[0.055em] bg-about bg-no-repeat bg-right bg-clip-text text-transparent">
              08
            </div> */}
            <img src={TechComputer} alt="Asesoria tecnica" className="w-full h-auto" />
          </motion.div>
          {/* text */}
          <motion.div
            variants={fadeIn("left")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true,}}
            className="flex-1 h-full xl:mt-5 xs:mt-5 sm:mt-5 xs:text-justify"
          >
            
            <div className="flex flex-col items-end">
              <div className="max-w-[530px] text-grey xs:mb-5">
              <p className="mb-6">En <span className='font-bold'>Inversiones DS SOLUTIONS C.A.</span>, estamos comprometidos con el éxito de tu proyecto. Nuestro equipo de expertos en tecnología está aquí para ofrecerte asesoría técnica personalizada, asegurando que cada etapa de tu proyecto se desarrolle de manera eficiente y efectiva.</p>
              <ul className='list-disc ml-6'>
                  <li><span className='font-bold'>Evaluación Inicial:</span> Analizamos tus necesidades y objetivos para diseñar una estrategia tecnológica que se alinee con tus metas.</li>
                  <li><span className='font-bold'>Implementación de Soluciones:</span> Te guiamos en la selección e implementación de las soluciones de software más adecuadas para tu proyecto.</li>
                  <li><span className='font-bold'>Optimización Continua:</span> Proporcionamos soporte continuo y optimización de sistemas para garantizar que tu tecnología funcione de manera óptima.</li>
                  <li><span className='font-bold'>Innovación y Mejora:</span> Te ayudamos a incorporar las últimas innovaciones tecnológicas para mantener tu proyecto a la vanguardia.</li>
                </ul>

              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default AsesoriaTecnica