import React, { useState } from "react";
// import contact data
import { contactData } from "../data";
// import motion
import { motion } from "framer-motion";
// import variants
import { fadeIn } from "../variants";
import axios from "axios";




const Contact = () => {
  // destructure contact data
  const { title, info, form } = contactData;
  const [showToast, setShowToast] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);


  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    descripcion: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setLoadingForm(true)
    e.preventDefault();

    if(formData.correo == '' && formData.descripcion == '' && formData.nombre == ''){
      setLoadingForm(false)
      setShowError(true);
      setTimeout(() => setShowError(false), 3000);
    }else{

      try {
        console.log('mi from data es ', formData)
        /* const response = await axios.post('http://192.168.0.106:8000/api/email/contactar', formData); */
        const response = await axios.post('https://apicorreo.inversiones-ds.com/api/email/contactar', formData);
        
        console.log('Form submitted successfully:', response.data);
        // Reset form or show success message
        setFormData({ nombre: '', correo: '', descripcion: '' });
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000); // Hide toast after 3 seconds
        setLoadingForm(false)
  
      } catch (error) {
        console.error('Error submitting form:', error);
        // Show error message to user
      }
    }

  };


  const Toast = ({ message, color }) => (
    <div className={`fixed bottom-4 right-4 ${color} text-white px-4 py-2 rounded shadow-lg`}>
      {message}
    </div>
  );

  return (
    <section className="section bg-gray-100" id="contact">
    {showToast && <Toast message="Mensaje enviado con éxito" color="bg-green-500" />}
    {showError && <Toast message="Rellena todos los campos" color="bg-red-500"/>}
      <div className="container mx-auto">
        <div className="flex flex-col xl:flex-row gap-y-16">
          {/* text */}
          <motion.div
            variants={fadeIn("right")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.4 }}
            className="flex-1"
          >
            {/* title */}
            <h2 className="h2 max-w-[490px]">{title}</h2>
            {/* info items */}
            <div className="flex flex-col xl:flex-row gap-x-5 gap-y-16 xl:gap-y-0">
              {info.map((item, index) => {
                // destructure item
                const { title, subtitle, address, phone, email, link } = item;
                return (
                  // item
                  <div key={index}>
                    {/* title */}
                    <div className="font-primary uppercase font-medium text-xl mb-3">
                      {title}
                    </div>
                    {/* subtitle */}
                    <div className="mb-6 text-[#333] leading-[187%] tracking-[0.02em]">
                      {subtitle}
                    </div>
                    {/* address, phone & email */}
                    <div className="flex flex-col gap-y-3 mb-8">
                      {/* address */}
                      <div className="flex items-center gap-[10px]">
                        <div>{address.icon}</div>
                        <div className="font-medium">{address.name}</div>
                      </div>
                      {/* phone */}
                      <div className="flex items-center gap-[10px]">
                        <div>{phone.icon}</div>
                        <div className="font-medium">{phone.number}</div>
                      </div>
                      {/* email */}
                      <div className="flex items-center gap-[10px]">
                        <div>{email.icon}</div>
                        <div className="font-medium">{email.address}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
          {/* form */}
          <motion.div
            variants={fadeIn("left")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.4 }}
            className="flex-1 xl:pl-[40px] flex justify-center items-center"
          >
            <form
              className="flex flex-col gap-y-10 w-full"
              onSubmit={handleSubmit}
            >
              <input
                className="border-b border-dark placeholder:text-[#555] italic tracking-[0.06em] outline-none pb-4"
                type="text"
                placeholder={form.name}
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
              />
              <input
                className="border-b border-dark placeholder:text-[#555] italic tracking-[0.06em] outline-none pb-4"
                type="text"
                placeholder={form.email}
                name="correo"
                value={formData.correo}
                onChange={handleChange}
              />
              <textarea
                className="border-b border-dark placeholder:text-[#555] italic tracking-[0.06em] outline-none pb-4 resize-none"
                placeholder={form.message}
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
              ></textarea>
              
              <button className="btn btn-sm btn-dark self-start" disabled={loadingForm}>
                {loadingForm ? 'Enviando...' : form.btnText}
                
              </button>
            </form>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Contact;