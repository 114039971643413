import React from 'react'
import { Helmet } from 'react-helmet-async'
import packageJson from '../../../package.json'

const Seo = ({title, description, image}) => {
  return (
    <Helmet>
        <title>{title}</title>
        <meta name='description' content={description}/>
        <meta property='og:title' content={title}/>
        <meta property='og:description' content={description}/>
        <meta property="og:image" content={`${packageJson.homepage}${image}`} />
        <meta property='og:url' content={window.location.href}/>
        <meta property='og:type' content='website'/>
    </Helmet>
  )
}

export default Seo