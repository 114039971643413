import React from "react";
//  useInView hook
import { useInView } from "react-intersection-observer";
// import motion
import { motion } from "framer-motion";
// import variants
import { fadeIn } from "../variants";
import integrations from "../img/services/integrations.png";
import DesarrolloWeb from "../img/services/desarrolloweb.png";
import DesarrolloSoftware from "../img/services/desarrollo-de-software.jpg";
import AsesoriaTecnica from "../img/services/asesoriatecnica.jpg";
import { Link } from "react-router-dom";



const Servicios = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  return (
    <section className="section-services pt-32 bg-white" id="servicios">
    <motion.section
      variants={fadeIn("up")}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.1 }}
      ref={ref}
      className="font-primary"
    >

      <h3 className="text-3xl font-bold text-center mb-8 text-gray-800">
        Servicios
      </h3>

      <div className="container mx-auto">
        <div className="flex flex-col xl:flex-row justify-between items-center gap-y-12">
          
          <div className="lg:w-[275px] sm:w-full flex flex-col justify-center items-center gap-y-6">
          <Link to='/desarrollo-software' className="block w-full">
            <div className="max-w-sm rounded overflow-hidden shadow-lg">
              <img className="w-full h-48 object-cover" src={DesarrolloSoftware} alt="Desarrollo de Software" />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Desarrollo de software</div>
                <p className="text-gray-700 text-base xs:text-justify lg:text-justify">
                En nuestra empresa, nos especializamos en el desarrollo de software personalizado para satisfacer las necesidades únicas de cada cliente.
                </p>
              </div>
            </div>
            </Link>
          </div>
          {/* w-[150px] */}
          <div className=" lg:w-[275px] sm:w-full flex flex-col justify-center items-center gap-y-6">
          <Link to='/integraciones' className="block w-full">
            <div className="max-w-sm rounded overflow-hidden shadow-lg">
              <img className="w-full h-48 object-cover" src={integrations} alt="Integraciones" />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Integraciones</div>
                <p className="text-gray-700 text-base xs:text-justify lg:text-justify">
                Nos especializamos en el desarrollo de software a medida y en la integración de sistemas ERP como SAP Business One, SAP R/3, SAP HANA, entre otros.
                </p>
              </div>
            </div>
            </Link>
          </div>

          <div className="lg:w-[275px] sm:w-full flex flex-col justify-center items-center gap-y-6">
            <Link to='/desarrollo-web' className="block w-full">
            <div className="max-w-sm rounded overflow-hidden shadow-lg">
            <img className="w-full h-48 object-cover" src={DesarrolloWeb} alt="Desarrollo Web" />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Desarrollo web</div>
                <p className="text-gray-700 text-base xs:text-justify lg:text-justify">
                  
                Nos especializamos en el desarrollo de aplicaciones web y móviles que impulsan la innovación y la eficiencia empresarial.
                </p>
              </div>
            </div>
            </Link>
          </div>
          

          <div className="lg:w-[275px] sm:w-full flex flex-col justify-center items-center gap-y-6">
          <Link to='/asesoria-tecnica' className="block w-full">
            <div className="max-w-sm rounded overflow-hidden shadow-lg">
              <img className="w-full h-48 object-cover" src={AsesoriaTecnica} alt="Asesoria técnica" />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Asesoria técnica</div>
                <p className="text-gray-700 text-base xs:text-justify lg:text-justify">
                Estamos comprometidos con el éxito de tu proyecto. Nuestro equipo de expertos en tecnología está aquí para ofrecerte asesoría técnica personalizada.
                </p>
              </div>
            </div>
          </Link>
          </div>
        </div>
      </div>
    </motion.section>  
      </section>
    
  );
};

export default Servicios;
