import React from "react";
import { aboutData } from "../data";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import about from '../img/about/aboutus.png';

const About = () => {
  const { title, subtitle1, subtitleMission, descriptionMission, subtitleVission, descriptionVission, btnText, btnIcon } = aboutData;
  return (
    <section className="lg:py-28 xl:pb-[160px] bg-gray-100" id="about">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-x-[70px] items-center">
          {/* numbers */}
          <motion.div
            variants={fadeIn("right")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="flex flex-col lg:flex-row flex-1"
          >
            {/* <div className="text-[260px] xl:text-[520px] leading-none font-tertiary lg:-tracking-[0.055em] bg-about bg-no-repeat bg-right bg-clip-text text-transparent">
              08
            </div> */}
            <img src={about} alt="Imagen sobre nosotros" className="w-full h-auto" />
          </motion.div>
          {/* text */}
          <motion.div
            variants={fadeIn("left")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.1}}
            className="flex-1 h-full"
          >
            <h2 className="h2 text-5xl mb-4">{title}</h2>
            <div className="flex flex-col items-end">
              <div className="max-w-[650px] text-grey">
                <p className="mb-2 xs:text-justify text-justify">{subtitle1}</p>
                <h2 className="mb-2 text-3xl font-bold">{subtitleMission}</h2>
                <p className="mb-2 xs:text-justify text-justify">{descriptionMission}</p>
                <h2 className="mb-2 text-3xl font-bold">{subtitleVission}</h2>
                <p className="mb-2 xs:text-justify text-justify">{descriptionVission}</p>

                {
                  btnText && 

                <button className="btn btn-lg btn-link">
                  <a href="/about">{btnText}</a>
                  <div className="text-xl">{btnIcon}</div>
                </button>
                }

              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
