import React from 'react';
import { hydrate, render } from "react-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';

const root = document.getElementById("root");

if (root.hasChildNodes()) {
  hydrate( <HelmetProvider><App /></HelmetProvider>, root);
} else {
  render(<HelmetProvider><App /></HelmetProvider>, root);
}

/* const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
); */

