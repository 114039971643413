import React, { useEffect } from 'react'

import ImageBanner from "../../img/services/desarrollo-de-software.jpg";
import SoftDevelopment from "../../img/services/details/soft-development.png";
import Header from '../Header';
import { motion, useScroll } from "framer-motion";
import { fadeIn } from "../../variants";
import { Link } from 'react-router-dom';

import Seo from '../seo/Seo';

const DesarrolloSoft = () => {

  const { scrollY } = useScroll();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

/*   const variants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 }
  };
 */
  return (
    <div className="max-w-[1920px] mx-auto overflow-hidden bg-white">
      
      <Seo title={'Inversiones DS Solutions - Desarrollo Software'} description={'Esta es la pagina de desarrollo de software'} image={ImageBanner}/>
        {/* <Header />
        <div className=" relative w-full h-64 md:h-96 mt-20"> */}
        <div className=" relative w-full h-64 md:h-96">
            <img 
                src={ImageBanner} 
                alt="" 
                className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <h1 className="text-white text-4xl md:text-6xl font-bold text-center">
                    Desarrollo de Software
                </h1>
            </div>
        </div>
        <Link to="/" className="inline-block px-4 py-2 mt-2 mb-2 ml-4 text-white bg-blue-500 rounded hover:bg-blue-600 transition duration-300 ease-in-out">
          Volver al inicio
        </Link>

        <section className="lg:py-16 xl:pb-[160px] bg-gray-100" id="about">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-x-[70px] items-center">
          {/* numbers */}
          <motion.div
            variants={fadeIn("right")}
            initial="hidden"
            whileInView={"show"}
            /* viewport={{ amount: 0.0 }} */
            viewport={{ once: true, }}
            
            className="flex flex-col lg:flex-row flex-1"
          >
            <img src={SoftDevelopment} alt="Asesoria tecnica" className="w-full h-auto" />
          </motion.div>
          {/* text */}
          <motion.div
          
            variants={fadeIn("left")}
            initial="hidden"
            whileInView={"show"}

            /* viewport={{ once: false, amount: 0.0 }} */
            viewport={{ once: true, }}
            className="flex-1 h-full xl:mt-5 xs:mt-5 sm:mt-5 xs:text-justify"
          >
            {/* <h2 className="text-4xl font-bold mb-4">Desarrollo de Software</h2> */}
            <div className="flex flex-col items-end">
              <div className="max-w-[530px] text-grey">
                <p className="mb-6">En <span className='font-bold'>Inversiones DS SOLUTIONS C.A.</span>, nos especializamos en el desarrollo de software personalizado para satisfacer las necesidades únicas de cada cliente. Contamos con un equipo de expertos en una amplia variedad de tecnologías y lenguajes de programación, incluyendo:</p>

                <ul className='list-disc ml-6'>
                  <li>Lenguajes de Programación: VB.NET, C#, JavaScript, HTML, React Native, Node.js.</li>
                  <li>Frameworks: Laravel, React, WordPress entre otros.</li>
                  <li>Bases de Datos: SQL Server, MySQL, MariaDB, y más.</li>
                </ul>

                <p className='mt-3 mb-4'>
                  Nuestro enfoque integral nos permite ofrecer soluciones robustas y escalables, desde aplicaciones web y móviles hasta sistemas empresariales complejos. Utilizamos las mejores prácticas de la industria para garantizar la calidad, seguridad y eficiencia en cada proyecto.
                </p>

                <h4 className='h4 font-bold'>Servicios que Ofrecemos:</h4>
                <ul className='list-disc ml-6 mb-4'>
                  <li>Desarrollo de aplicaciones web y móviles.</li>
                  <li>Integración de sistemas y APIs.</li>
                  <li>Mantenimiento y soporte técnico.</li>
                  <li>Consultoría en tecnología y transformación digital.</li>
                </ul>

                <h4 className='h4 font-bold'>¿Por qué Elegirnos?</h4>
                <ul className='list-disc ml-6'>
                  <li>Experiencia y Conocimiento: Más de 10 años de experiencia en el sector.</li>
                  <li>Calidad y Eficiencia: Nos comprometemos a entregar soluciones de alta calidad en tiempos óptimos.</li>
                  <li>Atención Personalizada: Trabajamos de la mano con nuestros clientes para entender sus necesidades y ofrecer soluciones a medida.</li>
                </ul>

              <p className='mt-4'>
                Contáctanos hoy para descubrir cómo podemos ayudarte a llevar tu negocio al siguiente nivel con nuestras soluciones de software innovadoras y personalizadas.
              </p>


              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default DesarrolloSoft