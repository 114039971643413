import React from "react";
import { heroData } from "../data";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import personImage from '../img/hero/person.png';
import logo from '../img/hero/logo.png';

const container = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.6,
    },
  },
};

const Hero = () => {
  const { title, subtitle, btnText, btnIcon } = heroData;
  return (
    <section className="bg-hero bg-cover bg-center min-h-[40vh] mt-2 lg:h-[848px] bg-no-repeat relative">
       {/*lg:h-[948px] final mt-[120px] lg:mt-[150px] */}
      <motion.div
        variants={container}
        initial="hidden"
        whileInView={"show"}
        className="container mx-auto min-h-[120vh] lg:h-full flex items-center justify-center xl:justify-end"
      >
            <motion.div
              className="flex flex-col lg:flex-row justify-center items-center space-y-8 lg:space-y-0 lg:space-x-8"
            >
              <motion.div
                variants={fadeIn("right", 0.7)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.7 }}
                className="w-full lg:w-1/2"
              >
                <img src={personImage} alt="Persona ofreciendo producto" className="w-full h-auto" />
              </motion.div>

              <motion.div
                variants={fadeIn("left", 0.8)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.7 }}
                className="w-full lg:w-1/2"
              >
                <img
                  className="w-[100] h-[100] mb-4 rounded-lg pl-4"
                  src={logo}
                  alt="Inversiones Ds Solutions"

                />

                <div className="bg-transparent p-6 rounded-lg">
                  <p className="text-gray-300">
                    Somos tu mejor alternativa a la hora de desarrollar tu idea de negocio.
                  </p>
                </div>
              </motion.div>
            </motion.div>
      </motion.div>
    </section>
  );
};

export default Hero;
