import React from "react";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AsesoriaTecnica from "./components/CardDescriptions/AsesoriaTecnica";
import Landing from "./Landing";
import DesarrolloWeb from "./components/CardDescriptions/DesarrolloWeb";
import DesarrolloSoft from "./components/CardDescriptions/DesarrolloSoftware";
import Integraciones from "./components/CardDescriptions/Integraciones";

const App = () => {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Landing/>}/>
          <Route path="/asesoria-tecnica" element={<AsesoriaTecnica/>}/>
          <Route path="/desarrollo-web" element={<DesarrolloWeb/>}/>
          <Route path="/desarrollo-software" element={<DesarrolloSoft/>}/>
          <Route path="/integraciones" element={<Integraciones/>}/>
        </Routes>
      </Router>
  );
};

export default App;
