import React, { useEffect } from 'react'
import ImageBanner from "../../img/services/integrations.png";
import Integrations from "../../img/services/details/integrations.png";
import Header from '../Header';
import { motion, useScroll } from "framer-motion";
import { fadeIn } from "../../variants";
import { Link } from 'react-router-dom';

import Seo from '../seo/Seo';

const Coding = () => {
  const { scrollY } = useScroll();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="max-w-[1920px] mx-auto overflow-hidden bg-white">
      <Seo title={'Inversiones DS Solutions - Integraciones'} description={'Esta es la pagina de desarrollo web'} image={ImageBanner}/>

      
        {/* <Header />
        <div className=" relative w-full h-64 md:h-96 mt-20"> */}
        <div className=" relative w-full h-64 md:h-96">
            <img 
                src={ImageBanner} 
                alt="Coding Banner" 
                className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <h1 className="text-white text-4xl md:text-6xl font-bold text-center">
                    Integraciones
                </h1>
            </div>
        </div>
        <Link to="/" className="inline-block px-4 py-2 mt-2 mb-2 ml-4 text-white bg-blue-500 rounded hover:bg-blue-600 transition duration-300 ease-in-out">
          Volver al inicio
        </Link>

        <section className="lg:py-16 xl:pb-[160px] bg-gray-100" id="about">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-x-[70px] items-center">
          {/* numbers */}
          <motion.div
            variants={fadeIn("right")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.1 }}
            className="flex flex-col lg:flex-row flex-1"
          >
            {/* <div className="text-[260px] xl:text-[520px] leading-none font-tertiary lg:-tracking-[0.055em] bg-about bg-no-repeat bg-right bg-clip-text text-transparent">
              08
            </div> */}
            <img src={Integrations} alt="Asesoria tecnica" className="w-full h-auto" />
          </motion.div>
          {/* text */}
          <motion.div
            variants={fadeIn("left")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.1 }}
            className="flex-1 h-full xl:mt-5 xs:mt-5 sm:mt-5 xs:text-justify"
          >
            {/* <h2 className="text-4xl font-bold mb-4">Integraciones</h2> */}
            <div className="flex flex-col items-end">
              <div className="max-w-[530px] text-grey">
                <p className="mb-6">En <span className='font-bold'>Inversiones DS SOLUTIONS C.A.</span>, nos especializamos en el desarrollo de software a medida y en la integración de sistemas ERP como SAP Business One, SAP R/3, SAP HANA, entre otros. Nuestro equipo de expertos está comprometido en ofrecer soluciones tecnológicas innovadoras que optimicen y transformen los procesos empresariales de nuestros clientes.</p>

                <ul className='list-disc ml-6'>
                  <li>Creamos aplicaciones y sistemas a medida que se adaptan a las necesidades específicas de tu negocio.</li>
                  <li>Facilitamos la integración de sistemas ERP como SAP Business One, SAP R/3, SAP HANA, mejorando la eficiencia y la gestión de recursos en tu empresa.</li>
                  <li>Ofrecemos asesoramiento experto para ayudarte a seleccionar y implementar las mejores soluciones tecnológicas.</li>
                  <li>Brindamos soporte continuo y mantenimiento para asegurar que tus sistemas funcionen de manera óptima.</li>
                </ul>

                <p className='mt-3 mb-4'>
                Si estás buscando una solución tecnológica que transforme tu negocio, no dudes en ponerte en contacto con nosotros. Estamos aquí para ayudarte a llevar tu empresa al siguiente nivel.
                </p>

              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default Coding